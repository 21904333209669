import cruds from "../3D/pp/cruds.png";
import coffee from "../3D/pp/coffee.png";
import cara from "../3D/pp/cara.png";
import carency from "../3D/pp/carency.png";
import landing from "../3D/pp/landing.png";
import tic from "../3D/pp/tic.png";
import weather from "../3D/pp/weather.png";

export default function Projects() {
  return (
    <div id="Projects" className="projects">
      <h2>Projects</h2>
      <div className="MCo">
        <div className="Co">
          <div className="flip-card">
            <div className="flip-card-front">
              <img src={weather} alt="img" />
            </div>
            <div className="flip-card-back">
              {/* <h3>CRUDS</h3> */}
              <a
                target="_blank"
                href="https://bara-barakat.github.io/Weather-App/"
              >
                This program aims to provide accurate and up-to-date information
                about the weather in selected cities.
              </a>
              <div class="tooltip">
                <span class="tooltiptext">Click on explanation</span>
              </div>
            </div>
          </div>

          {/* ********************* */}

          <div className="flip-card">
            <div className="flip-card-front">
              <img src={cruds} alt="img" />
            </div>
            <div className="flip-card-back">
              <a target="_blank" href="https://bara-barakat.github.io/Cruds/">
                CRUDS is a web application that allows users to create, read,
                update, delete, search.
              </a>
              <div class="tooltip">
                <span class="tooltiptext">Click on explanation</span>
              </div>
            </div>
          </div>
          {/* ********************* */}

          <div className="flip-card">
            <div className="flip-card-front">
              <img src={coffee} alt="img" />
            </div>
            <div className="flip-card-back">
              <a target="_blank" href="https://github.com/Bara-barakat/Coffee">
                Coffee
              </a>
              <div class="tooltip">
                <span class="tooltiptext">Click on explanation</span>
              </div>
            </div>
          </div>

          {/* ********************* */}
          <div className="flip-card">
            <div className="flip-card-front">
              <img src={carency} alt="img" />
            </div>
            <div className="flip-card-back">
              <a
                target="_blank"
                href="https://bara-barakat.github.io/Currency-Conversion./"
              >
                This program which stores the exchange rates for various
                currencies relative to USD.
              </a>
              <div class="tooltip">
                <span class="tooltiptext">Click on explanation</span>
              </div>
            </div>
          </div>

          {/* ********************* */}
          <div className="flip-card">
            <div className="flip-card-front">
              <img src={cara} alt="img" />
            </div>
            <div className="flip-card-back">
              <a
                target="_blank"
                href="https://bara-barakat.github.io/Ecommerce/"
              >
                The Cara website make with HTML and CSS.
              </a>
              <div class="tooltip">
                <span class="tooltiptext">Click on explanation</span>
              </div>
            </div>
          </div>

          {/* ********************* */}
          <div className="flip-card">
            <div className="flip-card-front">
              <img src={tic} alt="img" />
            </div>
            <div className="flip-card-back">
              <a target="_blank" href="https://bara-barakat.github.io/Tic-Tac/">
                tic tac toe.
              </a>
              <div class="tooltip">
                <span class="tooltiptext">Click on explanation</span>
              </div>
            </div>
          </div>

          {/* ********************* */}
          <div className="flip-card">
            <div className="flip-card-front">
              <img src={landing} alt="img" />
            </div>
            <div className="flip-card-back">
              <a
                target="_blank"
                href="https://bara-barakat.github.io/landing-page/"
              >
                landing page.
              </a>
              <div class="tooltip">
                <span class="tooltiptext">Click on explanation</span>
              </div>
            </div>
          </div>

          {/* ********************* */}
        </div>
      </div>
    </div>
  );
}
