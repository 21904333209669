import Agile from "../3D/ce/Agile.jpg";
import Mc1 from "../3D/ce/Screenshot3.png";
import Mc2 from "../3D/ce/Screenshot.png";
import p from "../3D/ce/P.jpg";
import Mt from "../3D/ce/Mt.jpg";
import C from "../3D/ce/c++.png";
import H from "../3D/ce/H.jpg";
import HP from "../3D/ce/hp.png";

export default function Ce() {
  return (
    <div className="Ce">
      <h2>Certificat</h2>
      <div className="con">
        <div className="con1">
          <img src={Agile} alt="img" />
          <img src={Mc1} alt="img" />
          <img src={Mc2} alt="img" />
          <img src={HP} alt="img" />
          <img src={p} alt="img" />
          <img src={Mt} alt="img" />
          <img src={C} alt="img" />
          <img src={H} alt="img" />
        </div>
      </div>
    </div>
  );
}
