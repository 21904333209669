import "./App.css";
import Nav from "./Com/Nav";
import Body from "./Com/Body";
import My from "./Com/My";
import Projects from "./Com/Projects";
import Contact from "./Com/Contact";
import Ce from "./Com/Ce";

function App() {
  return (
    <div className="App">
      <Nav />
      <Body />
      <My />
      <Projects />
      <Ce />
      <Contact />
    </div>
  );
}

export default App;
