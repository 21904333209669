import p from "../3D/p.jpg";
import CV from "../3D/Bara-Barakat.pdf";

export default function Body() {
  return (
    <div className="body">
      <div className="body_containerL">
        {/* <iframe
          src="https://my.spline.design/macbookprocopy-86516e4805f4f4322993def3b111961f/"
          frameborder="0"
          width="700px"
          height="600px"
        ></iframe> */}

        <h1>Hi Im</h1>
        <p>Bara Barakat</p>
        <h2>Front-End Developer (React)</h2>
        <h3>
          We specialize in website programming and development, providing clean
          code and pixel-perfect design I can create any project you want in a
          very short time.
        </h3>
        <div>
          <button>
            <a target="_blank" href={CV}>
              Dawnload CV
            </a>
          </button>
        </div>
      </div>
      <div className="body_containerR">
        <img src={p} alt="img" />
      </div>
    </div>
  );
}
