import HTML from "../3D/html.png";
import css from "../3D/css.png";
import boot from "../3D/bootstrap.png";
import tailwind from "../3D/tailwind-css.png";
import sass from "../3D/sass.png";
import pugjs from "../3D/pugjs.png";
import js from "../3D/javascript.png";
import react from "../3D/react.png";
import redux from "../3D/redux-react.png";
import node from "../3D/nodejs.png";
import npm from "../3D/npm.png";
import git from "../3D/git.png";
import postman from "../3D/postman.png";
import jira from "../3D/jira.png";

export default function My() {
  return (
    <div id="My Skills" className="My">
      <h2>My Skills</h2>
      <marquee>
        <div className="item">
          <div>
            <img src={HTML} alt="img" />
          </div>
          <div>
            <img src={css} alt="img" />
          </div>
          <div>
            <img src={boot} alt="img" />
          </div>
          <div>
            <img src={tailwind} alt="img" />
          </div>
          <div>
            <img src={sass} alt="img" />
          </div>
          <div>
            <img src={pugjs} alt="img" />
          </div>
          <div>
            <img src={js} alt="img" />
          </div>
          <div>
            <img src={react} alt="img" />
          </div>
          <div>
            <img src={redux} alt="img" />
          </div>
          {/* <div>
            <img src={node} alt="img" />
          </div> */}
          <div>
            <img src={npm} alt="img" />
          </div>
          <div>
            <img src={git} alt="img" />
          </div>
          <div>
            <img src={postman} alt="img" />
          </div>
          <div>
            <img src={jira} alt="img" />
          </div>
        </div>
      </marquee>
    </div>
  );
}
