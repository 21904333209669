import Bara from "../3D/Bara.png";

export default function Nav() {
  return (
    <nav className="navbar">
      <div className="containerL">
        <img src={Bara} alt="img" />
      </div>
      <div className="containerR">
        <a href="#My Skills">My Skills</a>
        <a href="#Projects">Projects</a>
        <a href="#Contact">Contact Me</a>
      </div>
    </nav>
  );
}
